import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  grid grid-cols-2 gap-x-2-4 mb-2-4
`

export const Button = styled.button`
  ${tw`relative border border-grey py-0-8 px-1-2 text-12 md:hover:underline`}
  span {
    ${tw`text-grey absolute left-1-2`}
  }
`

export const Alternative = tw.div`
  text-center text-12 py-0-2 mb-2-4
`

import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Label = styled.div<LabelProps>`
  ${tw`flex items-center relative cursor-pointer select-none`}
  ${({ halfWidth }) => (halfWidth ? tw`w-1/2` : null)}
  ${({ disabled }) => (disabled ? tw`cursor-not-allowed pointer-events-none` : null)}
`

export const StyledCheckbox = tw.input`absolute cursor-pointer opacity-0 h-0 w-0`

export const Marker = styled.span<MarkerProps>`
  ${tw`relative inset-0 h-1-6 w-1-6 mr-1 bg-transparent border border-grey transition-all duration-200`}
  ${({ type }) => (type === "radio" ? tw`rounded-full` : null)}
  ${({ type }) => (type === "checkbox" ? tw`` : null)}
  &:after {
    content: "";
    ${({ type }) =>
      type === "checkbox"
        ? tw`absolute top-0-2 left-0-5 w-0-4 h-0-8 border border-black transition-all duration-200 transform rotate-45 border-t-0 border-r-2 border-b-2 border-l-0 scale-0`
        : null}
    ${({ type }) =>
      type === "radio"
        ? tw`absolute top-0-2 left-0-2 w-1 h-1 bg-black border border-black rounded-full transition-all duration-200 transform scale-0`
        : null}
  }
  ${({ active }) =>
    active &&
    css`
      ${tw`bg-white border-black`}
      &:after {
        ${tw`scale-100`}
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      ${tw`bg-beige`}
    `}
`

type LabelProps = {
  halfWidth?: boolean
  disabled: boolean
}

type MarkerProps = {
  active: boolean
  disabled: boolean
  type: string
}

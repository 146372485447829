import tw, { styled } from "twin.macro"

export const NameWrapper = tw.div`
  flex flex-col md:flex-row
`

export const CheckboxWrapper = tw.div`
  mb-2
`

export const TermsWrapper = styled.div`
  ${tw`mb-2`}
  p {
    ${tw`text-grey text-12`}
  }
  a {
    ${tw`underline`}
  }
`

import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCustomerRegister } from "../../../../hooks/useCustomer"
import { useApp } from "../../../../hooks/useApp"

export const withRegisterForm = Component => ({ name = "RegisterForm" }) => {
  const { createCustomer, data, setData, loading, errors } = useCustomerRegister()

  const {
    config: {
      settings: {
        routes: { LOGIN },
      },
    },
  } = useApp()

  const { register } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_REGISTER {
      register: sanityPageAccountRegister {
        additionalFirstNamePlaceholder
        additionalLastNamePlaceholder
        additionalEmailPlaceholder
        additionalPasswordPlaceholder
        additionalNewsletterText
        additionalSubmitButton
        additionalTermsAndConditions: _rawAdditionalTermsAndConditions(resolveReferences: { maxDepth: 2 })
        additionalLoginText
        additionalLoginHighlightWords
      }
    }
  `)

  const {
    additionalFirstNamePlaceholder,
    additionalLastNamePlaceholder,
    additionalEmailPlaceholder,
    additionalPasswordPlaceholder,
    additionalNewsletterText,
    additionalSubmitButton,
    additionalTermsAndConditions,
    additionalLoginText,
    additionalLoginHighlightWords,
  } = register || {}

  const handleSubmit = async event => {
    event.preventDefault()
    const filteredData = Object.entries(data).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
    await createCustomer({
      ...filteredData,
    })
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const additionalLoginTextBreakdown = additionalLoginText.split(additionalLoginHighlightWords)

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      loginUrl={LOGIN}
      additionalFirstNamePlaceholder={additionalFirstNamePlaceholder}
      additionalLastNamePlaceholder={additionalLastNamePlaceholder}
      additionalEmailPlaceholder={additionalEmailPlaceholder}
      additionalPasswordPlaceholder={additionalPasswordPlaceholder}
      additionalNewsletterText={additionalNewsletterText}
      additionalSubmitButton={additionalSubmitButton}
      additionalTermsAndConditions={additionalTermsAndConditions}
      additionalLoginText={additionalLoginTextBreakdown}
      additionalLoginHighlightWords={additionalLoginHighlightWords}
    />
  )
}

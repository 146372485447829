import React from "react"

import { Body } from "../../Styled/Text"
import { Label, Marker, StyledCheckbox } from "./CheckboxStyles"

export const CheckboxInput = ({
  className = "",
  checked,
  children,
  name,
  handleChange,
  required = false,
  title,
  disabled = false,
  type = "checkbox",
}) => (
  <Label as={`label`} title={title} disabled={disabled} className={className}>
    <StyledCheckbox type={"checkbox"} checked={checked} name={name} onChange={handleChange} required={required} />
    <Marker active={checked} disabled={disabled} type={type} />
    <Body>{children}</Body>
  </Label>
)

export const CheckboxButton = ({ active, handleChange, title, children, halfWidth, disabled, type = "checkbox" }) => (
  <Label as={`label`} title={title} onClick={handleChange} halfWidth={halfWidth}>
    <Marker active={active} disabled={disabled} type={type} />
    <Body>{children}</Body>
  </Label>
)
